@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --radius: 0rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --tw-shadow-color: rgba(0, 0, 0, 0.05);
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --tw-shadow-color: rgba(255, 255, 255, 0.1);
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

.rtl {
  direction: rtl;
  text-align: right;
}


.image-tool__caption {
  border: none !important;
  /* Remove any border */
  pointer-events: none !important;
  /* Disable interactions */
  padding: 0px !important;
  /* Remove padding */
  box-shadow: none !important;
  /* Remove any shadow */
  background: transparent !important;
  /* Remove background */
  margin: 0px !important;
  /* Remove any margin */
  width: auto !important;
  /* Prevent width constraints */
  height: auto !important;
  /* Prevent height constraints */
}

.ce-block__content {
  max-width: 90% !important;
  margin: 0px 50px !important;
}

.ce-toolbar__content {
  max-width: none !important;
}

.ce-toolbar__actions {
  right: none !important;
  left: 0px !important
}

.d-none {
  display: none !important;
}

.codex-editor__redactor {
  padding-bottom: 0px !important;
}

/* 
.codex-editor--empty {
  display: none !important;
} */

.no-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and other WebKit browsers */
}

@font-face {
  font-family: "Reckless";
  src: url("/public/Reckless/RecklessNeue-Regular.ttf") format("truetype");
  font-weight: 400;
  /* Normal weight */
  font-style: normal;
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply box-border bg-background text-foreground;
    font-family: var(--font-inter);
  }

  h1,
  h2 {
    font-family: var(--font-reckless);
    /* Default to SemiBold */
  }

  li {
    font-family: var(--font-inter);
  }
}

.font-inter {
  font-family: var(--font-inter);
}

.font-reckless {
  font-family: var(--font-reckless);
}

h1 {
  font-size: 76px;
  font-weight: 400;
  line-height: 73px;
}

h2 {
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
}

h3 {
  font-size: 40px;
  font-weight: 500;
  line-height: 58px;
}

h4 {
  font-size: 26px;
  font-weight: 500;
  line-height: 32px;
}

h5 {
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
}

h6 {
  font-size: 26px;
  font-weight: 500;
  line-height: 32px;
}

label {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

p {
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
}

.subtitle1 {
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  color: #252b37;
}

.body {
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #252b37;
}

@layer components {
  .form-provider {
    form {
      color: inherit !important;
    }

    form * {
      color: inherit !important;
    }

    form a {
      @apply text-inherit !important;
      text-decoration: inherit !important;
    }
  }
}

@layer utilities {
  .min-h-screen {
    min-height: 100vh;
    /* Fallback */
    min-height: 100dvh;
  }

  .h-screen {
    height: 100vh;
    /* Fallback */
    height: 100dvh;
  }
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
  height: 0;
  /* height of the horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid transparent;
  /* creates padding around scroll thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7);
  /* color of the scroll thumb on hover */
}

/* Add this CSS to your global styles */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.toolbar {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

/* Ensure the toolbar is above other map elements */
.leaflet-top.leaflet-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other elements */
  pointer-events: auto;
  /* Ensure it is interactive */
}

.control-button {
  background-color: #ffffff;
  /* Example background color */
  border: 1px solid #bfbfbf;

  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
  z-index: 1000;
  /* Ensure button is above other elements */
}

.control-button.active {
  background-color: #005a9e;
  /* Active state color */
}

.control-button:hover {
  background-color: #005a9e;
}

/* Ensure leaflet map container allows pointer events */
.leaflet-container {
  pointer-events: auto;
}

/* Default font size for all screens */
.car-model-font-size {
  font-size: 16px;
  /* Base font size */
}

/* Adjust font size for tablets */
@media (min-width: 768px) and (max-width: 1030px) {
  .car-model-font-size {
    font-size: 12px !important;
    /* Smaller font for tablets */
  }

  .option {
    padding: 5px !important;
  }
}

/* Adjust font size for tablets */
@media (max-width: 768px) {
  .car-model-font-size {
    font-size: 14px;
    /* Smaller font for tablets */
  }

  .about_header strong {
    font-weight: 700 !important;
    font-size: 26px !important;
  }
}

/* Adjust font size for mobile devices */
@media (max-width: 480px) {
  .car-model-font-size {
    font-size: 12px;
    /* Even smaller font for mobile devices */
  }
}

.country-name {
  color: #000000 !important;
}

/* Add this CSS to your stylesheets or in a CSS-in-JS solution */

.react-select__control {
  border-color: var(--border-color);
  /* Default border color */
}

.react-select__control--is-focused {
  border-color: var(--primary-color);
  /* Border color on focus */
  box-shadow: 0 0 0 1px var(--primary-color);
  /* Focus ring */
}

.react-select__control:hover {
  border-color: var(--primary-color);
  /* Border color on hover */
}

/* Add this CSS to your stylesheet */

.react-select__control {
  border-color: var(--border-color);
  /* Default border color */
  outline: none;
  /* Remove default outline */
}

.react-select__control:focus-visible {
  border-color: var(--primary-color);
  /* Border color when focused and visible */
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.2);
  /* Focus ring */
}

/* Apply more specific CSS rules */
.react-select__control.react-select__control--is-focused {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.2) !important;
}

.phone-input {
  width: 100%;
  font-size: 15px;
  padding: 0.5rem 2.75rem;
  border-radius: 5px;
  border: 1px solid #e5e7eb;
  outline: none;
  transition:
    border-color 0.2s ease,
    box-shadow 0.2s ease;
}

.phone-input:focus-visible {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.2) !important;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #83819599 #f1f1f1;
}

.invoice-container {
  position: relative;
  overflow: hidden;
}

.ribbon {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 120px;
  height: 120px;
  z-index: 10;
  /* Ensure the ribbon is above other elements */
}

.ribbon-draft {
  background-color: #94a5a6;
  color: #ffffff;
}

.ribbon-invoice {
  background-color: #097969;
  color: #ffffff;
}

.ribbon-rejected {
  background-color: #dc3545;
  color: #ffffff;
}

.ribbon-unknown {
  background-color: #6c757d;
  color: #ffffff;
}

.ribbon .ribbon-inner {
  display: block;
  position: relative;
  top: 30px;
  left: -50px;
  width: 200px;
  padding: 5px 0;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  transform: rotate(-45deg);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.ribbon .ribbon-inner:before,
.ribbon .ribbon-inner:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: transparent;
}

.ribbon .ribbon-inner:before {
  top: 0;
  left: 0;
  border-width: 0 10px 10px 0;
  border-right-color: #788e8f;
}

.ribbon .ribbon-inner:after {
  bottom: 0;
  right: 0;
  border-width: 10px 0 0 10px;
  border-left-color: #788e8f;
}

/* styles/globals.css or styles/common.css */

/* Style for all unordered lists */
ul {
  margin-top: 1rem;
  /* Equivalent to mt-4 */
  /* color: #474747; */
  /* Equivalent to text-gray-800 */
  list-style-type: disc;
  /* List with bullets */
  padding-left: 1rem;
  /* Equivalent to list-inside in Tailwind */
}

li {
  font-size: 1rem !important;
  /* font-weight: 100 !important; */
  line-height: 1.75rem;
  margin-bottom: 1rem;
}

.footer li {
  margin: 0px !important;
  font-size: 12px !important;
}

.footer {
  list-style-type: none;
  padding: 0px;
}

.about-content li {
  font-size: 16px !important;
  font-weight: 300 !important;
}

.consulting-about-content p strong {
  font-size: 16px !important;
  color: #000000ed !important;
}

.consulting-about-content p {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #000000a4 !important;
}

.about-content ul {
  margin-left: 12px;
}

.about-content p strong {
  font-size: 30px !important;
  line-height: 1.5em;
}

/* .about-content h2 {
  padding-right: 40px;
} */

.about-content h2 strong {
  font-size: 30px !important;
  line-height: 1.5em;
}

.about-content p {
  width: 80%;
}

strong {
  font-weight: 600;
  line-height: 1.5em;
}

.decoding-li li {
  font-size: 14px !important;
  font-weight: 300 !important;
}

.horizontal-list {
  display: flex;
}

.vertical-list {
  display: block;
}

@media (min-width: 1460px) {
  .about-content p {
    width: 60%;
  }

  .about-content h2 {
    padding-right: 150px !important;
  }

  .about-content li {
    font-size: 20px !important;
    margin-right: 68px;
  }

  .consulting-about-content p strong {
    font-size: 21px !important;
    color: #000000d6 !important;
  }

  .consulting-about-content p {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: #000000a4 !important;
    line-height: 1.5em !important;
  }
}

@media (min-width: 1880px) {
  .insights-chip {
    bottom: -1rem !important;
  }

  .about-content h2 {
    padding-right: 250px !important;
  }

  .about-image-width {
    height: 40% !important;
  }

  .consulting-about-content p strong {
    font-size: 21px !important;
    color: #000000d6 !important;
  }

  .consulting-about-content p {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: #000000c0 !important;
    line-height: 1.8em !important;
  }
}

/* @media (min-width:1980px) {
  .about-image-width {
    height: 40% !important;
  }
} */

@media (min-width: 1024px) {

  /* Adjust font size on larger screens */
  li {
    font-size: 1.125rem;
    /* Equivalent to text-lg */
  }
}

/* Container styling */
/* .container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
} */

/* Top section with dashboard image */
.top-section {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.dashboard-image {
  width: 100%;
  max-width: 700px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Options container */
.options {
  display: flex;
  gap: 10px;
  justify-content: center;
}

/* Each option button styling */
.option {
  flex: 1;
  text-align: center;
  padding: 15px;
  background-color: #f1f3f5;
  /* Light grey background for inactive options */
  border-radius: 10px;
  color: #6c757d;
  font-weight: 500;
  transition:
    box-shadow 0.2s,
    background-color 0.2s,
    color 0.2s;
  cursor: pointer;
}

/* Highlighted (active) option */
.option.highlighted {
  background-color: #e6f4ff;
  /* Light blue background for active option */
  color: #ff6f00;
  /* Orange text for active option */
  border: 2px solid #ff6f00;
  /* Orange border */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

/* Hover effect for all options */
.option:hover {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.hero-section {
  background-color: black;
  color: white;
}

.hero-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.arrow-right {
  display: inline-block;
  width: 50px;
  /* Length of the arrow shaft */
  height: 2px;
  /* Thickness of the arrow shaft */
  background-color: white;
  /* Color of the arrow */

  position: relative;
}

.arrow-right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 8px solid white;
  /* Color of the arrowhead */
}

.about_header .ql-size-large {
  font-size: 50px !important;
  /* Adjust as needed */
}

.about_header strong {
  font-weight: 700;
  /* font-size: 58px; */
}

.ql-size-large {
  font-size: 50px;
  /* Adjust as needed */
}

.ql-size-small {
  font-size: 26px;
  /* Adjust as needed */
}

.ql-size-huge {
  font-size: 96px;
  /* Adjust as needed */
}

/* Text color and background color */
.ql-color-picker,
.ql-background-picker {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* Text formatting: bold, italic, underline, strike */
.ql-bold {
  font-weight: bold;
}

.ql-italic {
  font-style: italic;
}

.ql-underline {
  text-decoration: underline;
}

.ql-strike {
  text-decoration: line-through;
}

/* Text alignment options */
.ql-align .ql-picker-item[data-value="center"]::before {
  content: "Center";
}

.ql-align .ql-picker-item[data-value="right"]::before {
  content: "Right";
}

.ql-align .ql-picker-item[data-value="justify"]::before {
  content: "Justify";
}

.ql-align .ql-picker-item[data-value="left"]::before {
  content: "Left";
}

/* List styling */
.ql-list .ql-picker-item[data-value="ordered"]::before {
  content: "1.";
}

.ql-list .ql-picker-item[data-value="bullet"]::before {
  content: "•";
}

/* Link and image styling */
.ql-link,
.ql-image {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

/* Clean (remove formatting) button */
.ql-clean {
  color: #666;
  cursor: pointer;
}

/* Hover and active states for toolbar buttons */
.ql-toolbar .ql-formats button:hover {
  background-color: #f0f0f0;
}

.ql-toolbar .ql-formats button.ql-active {
  background-color: #e0e0e0;
}

.icon {
  width: 50px;
  height: 50px;
}

/* Slide in from the right */
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Slide out to the left */
@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* Slide in from the bottom */
@keyframes slideInBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Slide out to the top */
@keyframes slideOutTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* Container for the entire card */
.card-container {
  max-width: 800px;
  /* margin: 40px auto; */
  padding: 8px;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Image section styling */
.image-section {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 24px;
}

.main-image {
  width: 100%;
  height: auto;
  border-radius: 16px;
}

/* Services section styling */
.services-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
}

/* Individual service box styling */
.service-box {
  flex: 1;
  padding: 16px;
  background-color: #f9fafb;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  color: #6b7280;
  /* Default gray color */
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  cursor: pointer;
}

.service-box:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Active service box styling */
.service-box.active {
  color: #ff6f00;
  /* Orange color for active text */
  /* font-weight: bold; */
  border: 2px solid #ff6f00;
}

/* Optional: Media query for better responsiveness */
@media (max-width: 600px) {
  .services-section {
    flex-direction: column;
    gap: 12px;
  }

  .service-box {
    padding: 12px;
  }

  .arrow-right {
    display: none !important;
  }

  .decoding-li li {
    font-size: 14px !important;
    font-weight: 300 !important;
  }

  .about-content p strong {
    font-size: 17px !important;
    line-height: 0.8em;
  }

  .about-content h2 strong {
    font-size: 17px !important;
    line-height: 0.8em;
  }

  .about-content li {
    font-size: 14px !important;
    line-height: 1.5em;
  }

  .consulting-about-content p strong {
    font-size: 15px !important;
    color: #000000ed !important;
  }

  .consulting-about-content p {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #000000a4 !important;
  }

  .about-content ul {
    margin-left: 8px;
  }

  .outer-curve-button.active.first-button::after {
    right: -20px;
    top: -8px;
    /* Position the right curve */
    background-image: url("/images/about/curve_shadow_mobile.png");
    background-repeat: no-repeat;
    transform: rotate(349deg);
    /* If needed, rotate or flip the image */
    background-size: contain;
    /* Ensure the image fits within the box */
  }

  /* Middle buttons: Add curves on both sides */
  .outer-curve-button.active.middle-button::before {
    left: -20px;
    top: -8px;
    /* Position the left curve */
    background-image: url("/images/about/curve_shadow_mobile.png");
    background-repeat: no-repeat;
    transform: rotate(99deg);
    /* If needed, rotate or flip the image */
    background-size: contain;
    /* Ensure the image fits within the box */
  }

  .outer-curve-button.active.middle-button::after {
    right: -20px;
    top: -8px;
    /* Position the right curve */
    background-image: url("/images/about/curve_shadow_mobile.png");
    background-repeat: no-repeat;
    transform: rotate(349deg);
    /* If needed, rotate or flip the image */
    background-size: contain;
    /* Ensure the image fits within the box */
  }

  /* Last button: Only add a left-side curve */
  .outer-curve-button.active.last-button::before {
    left: -20px;
    top: -8px;
    /* Position the left curve */
    background-image: url("/images/about/curve_shadow_mobile.png");
    background-repeat: no-repeat;
    transform: rotate(99deg);
    /* If needed, rotate or flip the image */
    background-size: contain;
    /* Ensure the image fits within the box */
  }

  /* Adjust border-radius for buttons */
  .outer-curve-button.active.first-button {
    border-radius: 0 0 10px 10px;
    /* Rounded corners at the bottom */
  }

  .outer-curve-button.active.middle-button {
    border-radius: 0 0 10px 10px;
    /* Same for middle buttons */
  }

  .outer-curve-button.active.last-button {
    border-radius: 0 0 10px 10px;
    /* Rounded corners at the bottom */
  }

  /* Default non-active buttons: Square corners */
  .outer-curve-button:not(.active) {
    border-radius: 10px;
    /* Full-rounded corners */
  }
}

/* Base button styles */
.outer-curve-button {
  position: relative;
  overflow: visible;
  /* Ensure pseudo-elements are visible */
}

/* Active button: Add inner curves */
.outer-curve-button.active::before,
.outer-curve-button.active::after {
  content: "";
  position: absolute;
  top: -10px;
  /* Adjust the height position */
  width: 30px;
  /* Diameter of the half-circle */
  height: 30px;

  border-radius: 50%;
  /* Makes the pseudo-element circular */
  z-index: 1;
  /* Send behind the button for the desired effect */
}

/* First button: Only add a right-side curve */
.outer-curve-button.active.first-button::before {
  display: none;
  /* No left curve for the first button */
}

.outer-curve-button.active.first-button::after {
  right: -20px;
  /* Position the right curve */
  background-image: url("/images/about/curve.png");
  background-repeat: no-repeat;
  transform: rotate(349deg);
  /* If needed, rotate or flip the image */
  background-size: contain;
  /* Ensure the image fits within the box */
}

/* Middle buttons: Add curves on both sides */
.outer-curve-button.active.middle-button::before {
  left: -20px;
  /* Position the left curve */
  background-image: url("/images/about/curve.png");
  background-repeat: no-repeat;
  transform: rotate(99deg);
  /* If needed, rotate or flip the image */
  background-size: contain;
  /* Ensure the image fits within the box */
}

.outer-curve-button.active.middle-button::after {
  right: -20px;
  /* Position the right curve */
  background-image: url("/images/about/curve.png");
  background-repeat: no-repeat;
  transform: rotate(349deg);
  /* If needed, rotate or flip the image */
  background-size: contain;
  /* Ensure the image fits within the box */
}

/* Last button: Only add a left-side curve */
.outer-curve-button.active.last-button::before {
  left: -20px;
  /* Position the left curve */
  background-image: url("/images/about/curve.png");
  background-repeat: no-repeat;
  transform: rotate(99deg);
  /* If needed, rotate or flip the image */
  background-size: contain;
  /* Ensure the image fits within the box */
}

.outer-curve-button.active.last-button::after {
  display: none;
  /* No right curve for the last button */
}

/* Hide curves for non-active buttons */
.outer-curve-button:not(.active)::before,
.outer-curve-button:not(.active)::after {
  display: none;
}

/* Apply border-radius only when active */
.outer-curve-button.active {
  border-radius: 0;
  /* Reset default radius */
}

/* Adjust border-radius for buttons */
.outer-curve-button.active.first-button {
  border-radius: 0 0 16px 16px;
  /* Rounded corners at the bottom */
}

.outer-curve-button.active.middle-button {
  border-radius: 0 0 16px 16px;
  /* Same for middle buttons */
}

.outer-curve-button.active.last-button {
  border-radius: 0 0 16px 16px;
  /* Rounded corners at the bottom */
}

/* Default non-active buttons: Square corners */
.outer-curve-button:not(.active) {
  border-radius: 16px;
  /* Full-rounded corners */
}

/* Hide scrollbar for all browsers */
.no-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Edge */
}

/* styles.css */
.hide-scrollbar {
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Edge */
}

/* .maroon {
  color: #52002D !important;
} */

/* .bg-maroon{
background: #52002D;
} */

/* .bg-orange {
background: #ff6a47;
} */

/* .text-orange {
  color: #ff6a47;
  } */

/* .bg-darkblue {
    background: #101C57;
  } */

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}